import React, { useEffect, useContext, useState } from "react";
import _ from "lodash";
import { navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { ref, uploadBytes } from "firebase/storage";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import RichTextEditor from "../components/RichTextEditor";
import ImageUploader from "../components/ImageUploader";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import { validation } from "../utils/validation";
import { getCountries } from "../utils/getCountries";
import notify from "../utils/notify";
import { createAccount, getAccountImage } from "../api";
import { storage } from "../services/firebase";

const DashboardCreateClient = () => {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, getUser } = useContext(
    AuthContext
  );
  const { industries } = useContext(DataContext);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();
  const countries = getCountries();

  useEffect(async () => {
    await isAuthenticated();
    setLoading(false);
  }, []);

  const onEditorStateChange = (data) => {
    setValue("description", data);
  };

  const onImageUploadChange = (data) => {
    setValue("logo", data[0].file);
  };

  const fetchUserData = async (accountId) => {
    await getUser(accountId);
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      const account_id = data.accountid;
      const industry = data.industry.label;
      const country = data.country.label;

      let imageUrl = "";

      if (data.logo) {
        const imageRef = ref(storage, `logos/${account_id}`);
        await uploadBytes(imageRef, data.logo);
        console.log("Logo upload successful");

        // Get logo url
        imageUrl = await getAccountImage(account_id);
      }

      const formData = _.omit(data, "country", "logo", "accountid", "industry");
      const payload = {
        account_id,
        industry,
        country,
        imageUrl,
        ...formData,
      };
      console.log(payload);
      const response = await createAccount(account_id, "Client", payload);
      if (response) {
        reset();
        notify("Profile created successfully", "success");
        setTimeout(async () => {
          navigate(-1);
        }, 1000);
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-18">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  {loading ? (
                    <Skeleton
                      height={44}
                      containerClassName="w-100"
                      className="w-70"
                    />
                  ) : (
                    <h3 className="font-size-6 mb-0">
                      Create Client/Company Profile
                    </h3>
                  )}
                </div>
              </div>
              <div className="bg-white shadow-8 rounded p-8 py-sm-7 px-sm-11">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    class="close focus-reset"
                    aria-label="Close"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {loading ? (
                  <div className="row justify-content-center">
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                  </div>
                ) : (
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="logo"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Company Logo*
                          </label>
                          <Controller
                            name="logo"
                            control={control}
                            defaultValue=""
                            rules={validation.logo}
                            as={
                              <ImageUploader
                                onImageUploadChange={onImageUploadChange}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.logo?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="accountid"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Wallet ID*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="accountid"
                            name="accountid"
                            placeholder="companyname.near"
                            ref={register(validation.wallet)}
                          />
                          <span className="text-danger small">
                            {errors?.accountid?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="firstname"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            name="firstname"
                            placeholder="Company Representative First Name"
                            ref={register(validation.companyFirstName)}
                          />
                          <span className="text-danger small">
                            {errors?.firstname?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="lastname"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            name="lastname"
                            placeholder="Company Representative Last Name"
                            ref={register(validation.companyLastName)}
                          />
                          <span className="text-danger small">
                            {errors?.lastname?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="email"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Email*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Company Email"
                            ref={register(validation.email)}
                          />
                          <span className="text-danger small">
                            {errors?.email?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="company"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Company Name*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="company"
                            name="company"
                            placeholder="Company Name"
                            ref={register(validation.company)}
                          />
                          <span className="text-danger small">
                            {errors?.company?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="description"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Company Description*
                          </label>
                          <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            rules={validation.companyDescription}
                            as={
                              <RichTextEditor
                                onEditorStateChange={onEditorStateChange}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.description?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="website"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Website URL*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="website"
                            name="website"
                            placeholder="https://www.companyname.domain"
                            ref={register(validation.website)}
                          />
                          <span className="text-danger small">
                            {errors?.website?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="linkedin"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            LinkedIn URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="linkedin"
                            name="linkedin"
                            placeholder="https://linkedin.com/companyname"
                            ref={register(validation.linkedin)}
                          />
                          <span className="text-danger small">
                            {errors?.linkedin?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="twitter"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Twitter URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="twitter"
                            name="twitter"
                            placeholder="https://twitter.com/companyname"
                            ref={register(validation.twitter)}
                          />
                          <span className="text-danger small">
                            {errors?.twitter?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="github"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Github URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="github"
                            name="github"
                            placeholder="https://github.com/companyname"
                            ref={register(validation.companyGithub)}
                          />
                          <span className="text-danger small">
                            {errors?.github?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="industry"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Industry*
                          </label>
                          <Controller
                            name="industry"
                            control={control}
                            defaultValue=""
                            rules={validation.industry}
                            as={
                              <Select
                                options={industries}
                                className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                border={false}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.industry?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="stack"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Tech Stack
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="stack"
                            name="stack"
                            placeholder="Node.js, Python, Terraform, etc"
                            ref={register(validation.stack)}
                          />
                          <span className="text-danger small">
                            {errors?.stack?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="address"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Location*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            placeholder="Street Address"
                            ref={register(validation.location)}
                          />
                          <span className="text-danger small">
                            {errors?.address?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="city"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            City*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            placeholder="City"
                            ref={register(validation.city)}
                          />
                          <span className="text-danger small">
                            {errors?.city?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="country"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Country*
                          </label>
                          <Controller
                            name="country"
                            control={control}
                            defaultValue=""
                            rules={validation.country}
                            as={
                              <Select
                                options={countries}
                                className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                border={false}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.country?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-medium rounded-5 text-uppercase w-100 focus-reset"
                            disabled={submitting ? true : false}
                          >
                            {submitting
                              ? "Creating profile..."
                              : "Create Profile"}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          {errors &&
                            Object.values(errors).map((field, index) => (
                              <p
                                key={index}
                                className="text-danger small m-0 p-0"
                              >
                                {field.message}
                              </p>
                            ))}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardCreateClient;
